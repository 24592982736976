import { Field, ErrorMessage } from "formik";
import { Trans, withTranslation } from "react-i18next";

export default withTranslation()(function Market({ t }) {
	return (
		<div className="interaction__body">
			<Field
				className="input input--primary"
				placeholder={t("main.input.price")}
				name="price"
				type="text"
				autoComplete="off"
			/>
			<ErrorMessage
				name="price"
				render={(message) => (
					<div className="interaction__error">
						<Trans i18nKey={message} />
					</div>
				)}
			/>
		</div>
	);
});
