import { injected } from "@sources/extensions/modules/connectors";

export default ({ activate, dispatch }) =>
	injected
		.isAuthorized()
		.then((authorize: boolean) =>
			authorize === true
				? activate(injected, undefined, true)
						.then(() => dispatch({ type: "RS_LOADING", payload: false }))
						.catch(() => dispatch({ type: "RS_LOADING", payload: false }))
				: dispatch({ type: "RS_LOADING", payload: false })
		)
		.catch(() => dispatch({ type: "RS_LOADING", payload: false }));
