import { useEffect, useContext } from "react";

import Container from "@sources/pages/index/container";
import setListen from "@sources/extensions/modules/event";
import getTokens from "@sources/extensions/functions/requests/token";
import getEstates from "@sources/extensions/functions/requests/estate";
import { context } from "@sources/contexts/root";

export default function Index() {
	const { state, dispatch } = useContext(context);

	const estate = state.pages.index.attribute.loaded.estate;
	const token = state.pages.index.attribute.loaded.token;

	useEffect(() => {
		if (estate === false) {
			getEstates({ dispatch });
		}
	}, [estate, dispatch]);

	useEffect(() => {
		if (token === false) {
			getTokens({ dispatch });
		}
	}, [token, dispatch]);

	useEffect(() => {
		if (token === true) {
			setListen({ state, dispatch });
		}
	}, [token, dispatch]);

	return (
		<Container
			loading={state.resources.session.loading}
			editing={state.resources.session.editing}
			estate={state.pages.index.attribute.loading.estate}
			token={state.pages.index.attribute.loading.token}
			estates={state.pages.index.element.estate}
			tokens={state.pages.index.element.token}
		/>
	);
}
