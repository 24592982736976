import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { toNumber, map, split } from "lodash";

export const injected = new InjectedConnector({
	supportedChainIds: map(
		split(environment.NETWORK_ADDRESS_CHAINS, ","),
		toNumber
	),
});

export const getLibrary = (provider): Web3Provider => {
	const result = new Web3Provider(provider);
	result.pollingInterval = 12000;
	return result;
};
