import { Trans } from "react-i18next";

import {
	faGithubSquare,
	faTwitterSquare,
	faMedium,
	faTelegram,
	faDiscord,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const {
	INTERFACE_CONTENT_TWITTER: twitter,
	INTERFACE_CONTENT_DISCORD: discord,
	INTERFACE_CONTENT_TELEGRAM: telegram,
	INTERFACE_CONTENT_MEDIUM: medium,
	INTERFACE_CONTENT_GITHUB: github,
} = environment;

export default function Footer() {
	return (
		<footer className="footer">
			<div className="footer__text">
				<hr className="divider" />
				<Trans i18nKey="main.content.footer" />
			</div>
			<div className="footer__socials">
				<a
					href={twitter}
					className="footer__social"
					target="_blank"
					rel="nofollow noreferrer"
				>
					<FontAwesomeIcon icon={faTwitterSquare} size="lg" />
				</a>
				<a
					href={discord}
					className="footer__social"
					target="_blank"
					rel="nofollow noreferrer"
				>
					<FontAwesomeIcon icon={faDiscord} size="lg" />
				</a>
				<a
					href={telegram}
					className="footer__social"
					target="_blank"
					rel="nofollow noreferrer"
				>
					<FontAwesomeIcon icon={faTelegram} size="lg" />
				</a>
				<a
					href={medium}
					className="footer__social"
					target="_blank"
					rel="nofollow noreferrer"
				>
					<FontAwesomeIcon icon={faMedium} size="lg" />
				</a>
				<a
					href={github}
					className="footer__social"
					target="_blank"
					rel="nofollow noreferrer"
				>
					<FontAwesomeIcon icon={faGithubSquare} size="lg" />
				</a>
			</div>
		</footer>
	);
}
