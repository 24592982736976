import { Trans } from "react-i18next";

import Head from "@sources/pages/index/container/instruction/head";
import Foot from "@sources/pages/index/container/instruction/foot";

export default function Instruction({ layout, prices, price, type }) {
	const { width, height, block, row, cell } = layout;

	return (
		<div className="instruction">
			<div className="instruction__head">
				<Head size={row * cell} prices={prices} price={price} />
			</div>
			<div className="instruction__body">
				<Trans
					i18nKey="main.content.instruction"
					values={{
						size: row * cell,
						total: block * row * cell,
						width,
						height,
					}}
				/>
			</div>
			<div className="instruction__foot">
				<Foot type={type} />
			</div>
		</div>
	);
}
