import { has } from "lodash";

export default ({ log, element, type, dispatch }) =>
	element.map(({ id, duration }) =>
		has(log, id) === false
			? (log[id] = setTimeout(
					() => dispatch({ type, payload: id }),
					duration || 10000
			  ))
			: null
	);
