import { useContext, useEffect } from "react";
import { Trans } from "react-i18next";
import { reverse, split, includes, startsWith } from "lodash";

import { context } from "@sources/contexts/root";
import setTimeOut from "@sources/extensions/functions/helpers/timeout";

const log = {};
const {
	INTERFACE_CONTEXT_ERROR: error,
	INTERFACE_CONTEXT_WALLET: wallet,
	INTERFACE_CONTEXT_EXCLUDE: exclude,
	INTERFACE_CONTEXT_EXECUTION: execution,
} = environment;

export default function Notice() {
	const { state, dispatch } = useContext(context);
	const { element } = state.resources.notice;

	useEffect(
		() => setTimeOut({ log, element, type: "RNC_REMOVE", dispatch }),
		[element, dispatch]
	);

	return (
		<div className="notice">
			{reverse(element).map(({ id, type, header, content }, key) => {
				content = (
					startsWith(content, execution) === true
						? "side.error.contract.content." +
						  content.replace(execution, "").trim()
						: content
				)
					.replace(wallet, "")
					.replace(error, "")
					.trim();

				return content && includes(split(exclude, ","), content) === false ? (
					<div
						key={key}
						className="notice__item"
						onClick={() => dispatch({ type: "RNC_REMOVE", payload: id })}
					>
						<div className={"notice__content notice__content--" + type}>
							<div className="notice__head">
								<Trans i18nKey={header} />
							</div>
							<div className="notice__body">
								<Trans i18nKey={content} />
							</div>
						</div>
					</div>
				) : null;
			})}
		</div>
	);
}
