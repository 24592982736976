const regex = new RegExp(
	"^(https?:\\/\\/)?" +
		"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
		"((\\d{1,3}\\.){3}\\d{1,3}))" +
		"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
		"(\\?[;&a-z\\d%_.~+=-]*)?" +
		"(\\#[-a-z\\d_]*)?$",
	"i"
);

export default ({ values, errors, value, vertical, horizontal, t }) =>
	!!regex.test(value) === true && values.image !== value
		? new Promise((resolve) => {
				const image = new Image();
				image.src = value;

				image.addEventListener("load", () =>
					vertical >= image.height && horizontal >= image.width
						? resolve("")
						: resolve(
								t("side.error.interface.content.validation.size", {
									vertical,
									horizontal,
								})
						  )
				);

				image.addEventListener("error", () =>
					resolve("side.error.interface.content.validation.image")
				);
		  })
		: errors.image;
