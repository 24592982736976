import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import en from "@assets/locales/en";

export default i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		resources: { en: { translation: en } },
		fallbackLng: ["en"],
		whitelist: ["en"],
		detection: {
			order: ["querystring", "cookie", "navigator", "htmlTag"],
			lookupQuerystring: "language",
			lookupCookie: "language",
			caches: ["cookie"],
			cookieMinutes: 43200,
			htmlTag: document.documentElement,
			cookieOptions: { path: "/" },
		},
		debug: false,
		interpolation: { escapeValue: false },
	});
