import { ethers } from "ethers";
import { find } from "lodash";

import Service from "@assets/interfaces/Service";

const { NETWORK_ADDRESS_CONTRACT: contract, NETWORK_GATEWAY_SOCKET: socket } =
	environment;

export default ({ state, dispatch }) =>
	new ethers.Contract(
		contract,
		Service,
		new ethers.providers.WebSocketProvider(socket)
	)
		.on("GetPrice", (price) => {
			dispatch({ type: "PIU_PRICE", payload: price });
		})
		.on("Transfer", (from, to, id) => {
			dispatch({ type: "PIET_REMOVE", payload: id.toHexString() });
			dispatch({
				type: "PIET_PUSH",
				payload: {
					...find(
						state.pages.index.element.token,
						(i) => Number(i.id) === Number(id)
					),
					owner: to,
				},
			});
		})
		.on("SetToken", (id, block, vertical, horizontal, owner) => {
			dispatch({
				type: "PIET_PUSH",
				payload: {
					id: id.toHexString(),
					block,
					vertical,
					horizontal,
					owner,
				},
			});
		})
		.on("SetEstate", (id, title, image, link, block, vertical, horizontal) => {
			dispatch({
				type: "PIEE_PUSH",
				payload: {
					id: id.toHexString(),
					title,
					image,
					link,
					block,
					verticalStart: vertical.start,
					verticalEnd: vertical.end,
					horizontalStart: horizontal.start,
					horizontalEnd: horizontal.end,
				},
			});
		})
		.on("RemoveEstate", (id) => {
			dispatch({ type: "PIEE_REMOVE", payload: id.toHexString() });
		})
		.on("SetMarket", (id, price) => {
			dispatch({ type: "PIET_REMOVE", payload: id.toHexString() });
			dispatch({
				type: "PIET_PUSH",
				payload: {
					...find(
						state.pages.index.element.token,
						(i) => Number(i.id) === Number(id)
					),
					price,
					status: true,
				},
			});
		})
		.on("RemoveMarket", (id) => {
			dispatch({ type: "PIET_REMOVE", payload: id.toHexString() });
			dispatch({
				type: "PIET_PUSH",
				payload: {
					...find(
						state.pages.index.element.token,
						(i) => Number(i.id) === Number(id)
					),
					price: null,
					status: false,
				},
			});
		});
