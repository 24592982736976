import { gql } from "@apollo/client";

export const TOKENS = gql`
	query tokens($first: Int!, $skip: Int!) {
		tokens(first: $first, skip: $skip) {
			id
			block
			vertical
			horizontal
			owner
			price
			status
		}
	}
`;

export const ESTATES = gql`
	query estates($first: Int!, $skip: Int!) {
		estates(where: { status: true }, first: $first, skip: $skip) {
			id
			title
			image
			link
			block
			verticalStart
			verticalEnd
			horizontalStart
			horizontalEnd
		}
	}
`;
