import { Trans } from "react-i18next";

const content = {
	"token.set": "main.header.interact.token.set",
	"estate.set": "main.header.interact.estate.set",
	"estate.remove": "main.header.interact.estate.remove",
	"market.set": "main.header.interact.market.set",
	"market.buy": "main.header.interact.market.buy",
	"market.remove": "main.header.interact.market.remove",
};

export default function Head({ index, type, block, frame, setToggle }) {
	return (
		<div className="interaction__head" onClick={() => setToggle(index)}>
			<div className="interaction__title">
				<Trans i18nKey={content[type]} />
			</div>
			<div className="interaction__frame">
				<Trans i18nKey="main.content.frame" values={{ block, ...frame }} />
			</div>
		</div>
	);
}
