import { filter } from "lodash";

export const rni = { element: [] };

export const rnr = ({ resources }, { type, payload }) => {
	switch (type) {
		case "RNC_PUSH":
			return {
				element: resources.notice.element.concat([
					{ ...payload, id: new Date().getTime() },
				]),
			};
		case "RNC_REMOVE":
			return {
				element: filter(resources.notice.element, (i) => i.id !== payload),
			};
		default:
			return resources.notice;
	}
};
