import getToken from "@sources/extensions/functions/elements/token";

export default ({ tokens, estates, account }) => {
	return tokens.reduce(
		(y, { block, ...token }) =>
			Object.assign(y, {
				[block]: y[block]
					? y[block].concat(getToken({ block, ...token }, estates, account))
					: [getToken({ block, ...token }, estates, account)],
			}),
		{}
	);
};
