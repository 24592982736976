import { createContext } from "react";

import { rsr, rsi } from "@sources/contexts/resources/session";
import { rnr, rni } from "@sources/contexts/resources/notice";
import { pir, pii } from "@sources/contexts/pages/index";

export const context = createContext();

export const initial = {
	resources: { session: rsi, notice: rni },
	pages: { index: pii },
};

export const reducer = (state, { type, payload }) => ({
	resources: {
		session: rsr(state, { type, payload }),
		notice: rnr(state, { type, payload }),
	},
	pages: {
		index: pir(state, { type, payload }),
	},
});
