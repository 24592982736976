import { get } from "lodash";

import object from "@assets/objects/select";
import getModule from "@sources/extensions/modules/contract";

export default ({ block, frame, library, dispatch, setLoading, setSelect }) => {
	setLoading(true);

	getModule({ library })
		.removeEstate(block, [frame.vs, frame.ve], [frame.hs, frame.he])
		.then((transaction) =>
			transaction.wait().then(() => {
				setLoading(false);
				setSelect(object);

				dispatch({
					type: "RNC_PUSH",
					payload: {
						type: "success",
						header: "side.success.contract.header",
						content: "side.success.contract.content.estate.remove",
					},
				});
			})
		)
		.catch((error) => {
			setLoading(false);
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.contract.header",
					content: get(error, "data.message", get(error, "message", "")),
				},
			});
		});
};
