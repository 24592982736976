import { slice, get } from "lodash";

export default ({ prices }) =>
	prices.reduce(
		(ya, xa, i) =>
			get(ya[ya.length - 1], "cost") !== xa
				? ya.concat({
						cost: xa,
						start: i,
						end:
							i +
							slice(prices, i + 1).reduce(
								(yb, xb) => (xa === xb ? yb + 1 : yb),
								1
							),
				  })
				: ya,
		[]
	);
