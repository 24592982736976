export const rsi = { loading: true, editing: false };

export const rsr = ({ resources }, { type, payload }) => {
	switch (type) {
		case "RS_LOADING":
			return { ...resources.session, loading: payload };
		case "RS_EDITING":
			return { ...resources.session, editing: payload };
		default:
			return resources.session;
	}
};
