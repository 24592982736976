import { memo } from "react";
import { size } from "lodash";

import object from "@assets/objects/style";
import Asset from "@sources/pages/index/container/dashboard/estate/asset";

export default memo(
	function Estate({ estate }) {
		return estate.map(
			(
				{
					title,
					image,
					link,
					verticalStart: vs,
					verticalEnd: ve,
					horizontalStart: hs,
					horizontalEnd: he,
				},
				key
			) => (
				<Asset
					key={key}
					title={title}
					image={image}
					link={link}
					vertical={(ve - vs + 1) * 32}
					horizontal={(he - hs + 1) * 32}
					style={
						object.top[vs] +
						" " +
						object.left[hs] +
						" " +
						object.width[Math.abs(he - hs)] +
						" " +
						object.height[Math.abs(ve - vs)]
					}
				/>
			)
		);
	},
	(previous, next) => size(previous.estate) === size(next.estate)
);
