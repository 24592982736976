import { Trans } from "react-i18next";

import { faUsersCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Promote() {
	return (
		<main className="main main--service">
			<FontAwesomeIcon icon={faUsersCog} size="3x" />
			<h2>
				<Trans i18nKey="main.header.promote" />
			</h2>
			<Trans i18nKey="main.content.promote" />
		</main>
	);
}
