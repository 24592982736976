import { Trans } from "react-i18next";

export default function Foot({ type }) {
	return ["void", "mint", "onsale", "owner", "ownsale", "attach"].map(
		(value, key) => (
			<div key={key} className="instruction__type">
				<div
					data-type={value}
					className={
						"instruction__item " +
						(type === value && "instruction__item--active")
					}
				>
					<Trans i18nKey={`main.content.inline.type.${value}`} />
				</div>
			</div>
		)
	);
}
