import { UnsupportedChainIdError } from "@web3-react/core";
import {
	NoEthereumProviderError,
	UserRejectedRequestError,
} from "@web3-react/injected-connector";
import { injected } from "@sources/extensions/modules/connectors";

export default ({ activate, dispatch }) => {
	activate(injected, undefined, true).catch((error) => {
		if (error instanceof NoEthereumProviderError) {
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.interface.header",
					content: "side.error.interface.content.wallet.connect",
				},
			});
		} else if (error instanceof UserRejectedRequestError) {
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.interface.header",
					content: "side.error.interface.content.wallet.request",
				},
			});
		} else if (error instanceof UnsupportedChainIdError) {
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.interface.header",
					content: "side.error.interface.content.wallet.network",
				},
			});
		} else {
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.interface.header",
					content: "side.error.interface.content.wallet.unknown",
				},
			});
		}
	});
};
