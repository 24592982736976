import { size } from "lodash";

import client from "@sources/extensions/graphs/client";
import { TOKENS } from "@sources/extensions/graphs/query";

export default async ({ dispatch }, element = []) => {
	for (let i = 0; i < 2; i++) {
		const result = await client.query({
			query: TOKENS,
			variables: { first: 1000, skip: 1000 * i },
			policy: { fetchPolicy: "cache-first" },
		});

		element = element.concat(result.data.tokens);

		if (size(result.data.tokens) < 1000) break;
	}

	dispatch({ type: "PIET_STATE", payload: element });
};
