import { useState } from "react";
import { withRouter } from "react-router-dom";

import Toggle from "@sources/components/header/toggle";
import Status from "@sources/components/header/status";
import Wallet from "@sources/components/header/wallet";
import Navigator from "@sources/components/header/navigator";

export default withRouter(function Header({ history }) {
	const [active, setActive] = useState(false);

	const paths = history.location.pathname.replace("/", "").split("/");
	const page = paths[0].length > 0 ? paths[0] : "dashboard";
	const params = paths.slice(1, paths.length);

	return (
		<header className="header">
			<div className="header__basic">
				<div className="header__logo">
					<div className="header__logo__item">non</div>
					<div className="header__logo__item">
						<div>fungible</div>
						<div>pixels</div>
					</div>
				</div>
				<div className="header__toggle">
					<Toggle active={active} setActive={setActive} />
				</div>
			</div>
			<div
				className={
					"header__action " + (active === true && "header__action--active")
				}
			>
				<div className="header__navigator">
					<Navigator page={page} />
				</div>
				<div className="header__status">
					<Status page={page} />
				</div>
				<div className="header__wallet">
					<Wallet page={page} params={params} />
				</div>
			</div>
		</header>
	);
});
