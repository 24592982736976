import { memo, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import { isEqual, range, size, get } from "lodash";

import object from "@assets/objects/select";
import getEstates from "@sources/extensions/functions/elements/estates";
import getTokens from "@sources/extensions/functions/elements/tokens";
import Estate from "@sources/pages/index/container/dashboard/estate";
import Token from "@sources/pages/index/container/dashboard/token";

export default memo(
	function Dashboard({
		layout,
		token: tokens,
		estate: estates,
		select,
		setSelect,
	}) {
		const { account } = useWeb3React();

		const estate = useMemo(() => getEstates({ estates }), [estates]);
		const token = useMemo(
			() => getTokens({ tokens, estates, account }),
			[tokens, estates, account]
		);

		return (
			<div className="dashboard">
				{range(0, layout.block).map((block) => (
					<div key={block} className="dashboard__block">
						<div className="container">
							<Estate estate={get(estate, block, [])} />
						</div>
						<div className="container">
							<Token
								block={block}
								layout={layout}
								account={account}
								token={get(token, block, [])}
								select={select.block === block ? select : object}
								setSelect={setSelect}
							/>
						</div>
					</div>
				))}
			</div>
		);
	},
	(previous, next) =>
		size(previous.estate) === size(next.estate) &&
		size(previous.token) === size(next.token) &&
		previous.select.type === next.select.type &&
		previous.select.block === next.select.block &&
		isEqual(previous.select.frame, next.select.frame) === true
);
