import Market from "@sources/pages/index/container/interaction/body/market";
import Estate from "@sources/pages/index/container/interaction/body/estate";

export default function Body({ type, values, errors, frame }) {
	return type === "market.set" ? (
		<Market values={values} errors={errors} frame={frame} />
	) : type === "estate.set" ? (
		<Estate values={values} errors={errors} frame={frame} />
	) : null;
}
