import { useRef, useEffect } from "react";
import { update } from "jdenticon";

export default function Identicon({ value, vertical, horizontal }) {
	const icon = useRef(null);
	useEffect(() => update(icon.current, value), [value]);

	return (
		<svg
			data-jdenticon-value={value}
			vertical={vertical}
			horizontal={horizontal}
			ref={icon}
		/>
	);
}
