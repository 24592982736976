import { memo } from "react";
import { isNil, isEqual, size, range, find, get } from "lodash";

import Selection from "@sources/pages/index/container/dashboard/token/selection";

export default memo(
	function Token({ block, layout, token: tokens, select, setSelect }) {
		return range(0, layout.row).map((row) => (
			<div key={row} className="dashboard__row">
				{range(0, layout.cell).map((cell) => {
					const index = find(
						tokens,
						({ vertical, horizontal }) =>
							vertical === row && horizontal === cell
					);

					return (
						<Selection
							key={cell}
							block={block}
							frame={{ row, cell }}
							price={get(index, "price")}
							select={select}
							type={
								isNil(index) === false
									? index.status === true && index.owner === true
										? "ownsale"
										: index.status === true && index.owner === false
										? "onsale"
										: index.attach === true && index.owner === true
										? "attach"
										: index.owner === true
										? "owner"
										: "mint"
									: "void"
							}
							isSelect={
								select.block === block &&
								select.frame.vs <= row &&
								select.frame.ve >= row &&
								select.frame.hs <= cell &&
								select.frame.he >= cell
							}
							setSelect={setSelect}
						/>
					);
				})}
			</div>
		));
	},
	(previous, next) =>
		previous.account === next.account &&
		size(previous.token) === size(next.token) &&
		previous.select.type === next.select.type &&
		previous.select.block === next.select.block &&
		isEqual(previous.select.frame, next.select.frame) === true
);
