import { useContext, useState } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { Trans } from "react-i18next";
import { includes } from "lodash";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { context } from "@sources/contexts/root";
import onConnect from "@sources/extensions/functions/helpers/connect";
import onPromote from "@sources/extensions/functions/helpers/promote";

export default function Wallet({ page, params }) {
	const [spinning, setSpinning] = useState(false);
	const { library, active, error, activate } = useWeb3React();
	const { state, dispatch } = useContext(context);
	const { loading, editing } = state.resources.session;

	return includes(["dashboard", "promote"], page) === true &&
		(loading === true || spinning === true) ? (
		<button className="button button--secondary" disabled={true}>
			<FontAwesomeIcon icon={faCircleNotch} spin />
		</button>
	) : includes(["dashboard", "promote"], page) === true &&
	  error instanceof UnsupportedChainIdError === true ? (
		<button className="button button--error">
			<Trans i18nKey="main.button.error" />
		</button>
	) : includes(["dashboard", "promote"], page) === true &&
	  !!error === false &&
	  active === false ? (
		<button
			className="button button--secondary"
			onClick={() => onConnect({ activate, dispatch })}
		>
			<Trans i18nKey="main.button.connect" />
		</button>
	) : page === "dashboard" &&
	  !!error === false &&
	  active === true &&
	  editing === true ? (
		<button
			className="button button--primary"
			onClick={() => dispatch({ type: "RS_EDITING", payload: false })}
		>
			<Trans i18nKey="main.button.action.close" />
		</button>
	) : page === "dashboard" &&
	  !!error === false &&
	  active === true &&
	  editing === false ? (
		<button
			className="button button--primary"
			onClick={() => dispatch({ type: "RS_EDITING", payload: true })}
		>
			<Trans i18nKey="main.button.action.open" />
		</button>
	) : page === "promote" && !!error === false && active === true ? (
		<button
			className="button button--secondary"
			onClick={() =>
				onPromote({
					library,
					dispatch,
					message: params[0],
					setLoading: setSpinning,
				})
			}
		>
			<Trans i18nKey="main.button.promote" />
		</button>
	) : null;
}
