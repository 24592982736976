import axios from "axios";

export default ({ library, dispatch, message, setLoading }) => {
	setLoading(true);

	library
		.getSigner()
		.signMessage(message)
		.then((signature) =>
			axios
				.post(environment.NETWORK_SERVICE_PROMOTE, { message, signature })
				.then(() => {
					setLoading(false);

					dispatch({
						type: "RNC_PUSH",
						payload: {
							type: "success",
							header: "side.success.service.header",
							content: "side.success.service.content.promote",
						},
					});
				})
				.catch(() => {
					setLoading(false);

					dispatch({
						type: "RNC_PUSH",
						payload: {
							type: "error",
							header: "side.error.service.header",
							content: "side.error.service.content.promote",
						},
					});
				})
		)
		.catch(() => {
			setLoading(false);
		});
};
