import { get } from "lodash";

import object from "@assets/objects/select";
import getModule from "@sources/extensions/modules/contract";

export default ({
	values,
	block,
	frame,
	library,
	dispatch,
	setLoading,
	setSelect,
	resetForm,
}) => {
	setLoading(true);

	getModule({ library })
		.setEstate(
			values.title,
			values.image,
			values.link,
			block,
			[frame.vs, frame.ve],
			[frame.hs, frame.he]
		)
		.then((transaction) =>
			transaction.wait().then(() => {
				setLoading(false);
				setSelect(object);
				resetForm();

				dispatch({
					type: "RNC_PUSH",
					payload: {
						type: "success",
						header: "side.success.contract.header",
						content: "side.success.contract.content.estate.set",
					},
				});
			})
		)
		.catch((error) => {
			setLoading(false);
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.contract.header",
					content: get(error, "data.message", get(error, "message", "")),
				},
			});
		});
};
