import { filter } from "lodash";

export const pii = {
	attribute: {
		loading: { token: true, estate: true },
		loaded: { token: false, estate: false },
	},
	element: { token: [], estate: [] },
	utility: { price: null },
};

export const pir = ({ pages }, { type, payload }) => {
	switch (type) {
		case "PIU_PRICE":
			return { ...pages.index, utility: { price: payload } };
		case "PICE_STATE":
			return {
				...pages.index,
				attribute: {
					loading: { ...pages.index.attribute.loading, estate: false },
					loaded: { ...pages.index.attribute.loaded, estate: true },
				},
				element: { ...pages.index.element, estate: payload },
			};
		case "PIET_STATE":
			return {
				...pages.index,
				attribute: {
					loading: { ...pages.index.attribute.loading, token: false },
					loaded: { ...pages.index.attribute.loaded, token: true },
				},
				element: { ...pages.index.element, token: payload },
			};
		case "PIEE_PUSH":
			return {
				...pages.index,
				element: {
					...pages.index.element,
					estate: pages.index.element.estate.concat([payload]),
				},
			};
		case "PIET_PUSH":
			return {
				...pages.index,
				element: {
					...pages.index.element,
					token: pages.index.element.token.concat([payload]),
				},
			};
		case "PIET_REMOVE":
			return {
				...pages.index,
				element: {
					...pages.index.element,
					token: filter(
						pages.index.element.token,
						(i) => Number(i.id) !== Number(payload)
					),
				},
			};
		case "PIEE_REMOVE":
			return {
				...pages.index,
				element: {
					...pages.index.element,
					estate: filter(
						pages.index.element.estate,
						(i) => Number(i.id) !== Number(payload)
					),
				},
			};
		default:
			return pages.index;
	}
};
