import { Trans, withTranslation } from "react-i18next";
import { utils } from "ethers";
import { includes, isNil } from "lodash";

import {
	faExclamation,
	faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const signular = ["market.set", "market.buy", "market.remove"];
const content = {
	"token.set": "main.button.mint",
	"estate.set": "main.button.create",
	"estate.remove": "main.button.remove",
	"market.set": "main.button.create",
	"market.buy": "main.button.buy",
	"market.remove": "main.button.remove",
};

export default withTranslation()(function Foot({
	type,
	price,
	size,
	payment,
	loading,
	isValidating,
	t,
}) {
	return (
		<div className="interaction__foot">
			<div className="interaction__column">
				{isNil(payment) === false && type === "market.buy" ? (
					<>
						<div className="interaction__price">
							{utils.formatEther(payment)}{" "}
							{environment.INTERFACE_CONTENT_TICKER}
						</div>
						<div className="interaction__summary">
							<Trans i18nKey="main.content.payment" />
						</div>
					</>
				) : isNil(size) === false &&
				  isNil(price) === false &&
				  type === "token.set" ? (
					<>
						<div className="interaction__price">
							{utils.formatEther(price) * size}{" "}
							{environment.INTERFACE_CONTENT_TICKER}
						</div>
						<div className="interaction__summary">
							<Trans i18nKey="main.content.price" />
						</div>
					</>
				) : null}
			</div>
			<div className="interaction__column">
				{includes(signular, type) === true && size > 1 ? (
					<button
						type="submit"
						className="button button--secondary"
						title={t("main.title.size")}
						disabled={true}
					>
						<FontAwesomeIcon icon={faExclamation} />
					</button>
				) : isValidating === true || loading === true ? (
					<button
						type="submit"
						className="button button--secondary"
						disabled={true}
					>
						<FontAwesomeIcon icon={faCircleNotch} spin />
					</button>
				) : (
					<button type="submit" className="button button--secondary">
						<Trans i18nKey={content[type]} />
					</button>
				)}
			</div>
		</div>
	);
});
