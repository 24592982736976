import Identicon from "@sources/extensions/packages/identicon";

export default function Asset({
	title,
	image,
	link,
	style,
	vertical,
	horizontal,
}) {
	return (
		<a
			href={link}
			title={title}
			target="_blank"
			rel="noreferrer"
			className={`dashboard__image ${style}`}
		>
			<Identicon value={title} vertical={vertical} horizontal={horizontal} />
			<img
				src={image}
				alt={title}
				style={{ display: "none" }}
				onLoad={({ target }) => {
					target.parentNode.childNodes[0].style.display = "none";
					target.parentNode.childNodes[1].style.display = "block";
				}}
				onError={({ target }) => {
					target.parentNode.childNodes[0].style.display = "block";
					target.parentNode.childNodes[1].style.display = "none";
				}}
			/>
		</a>
	);
}
