import { useEffect, useReducer } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Index from "@sources/pages/index";
import Watcher from "@sources/pages/watcher";
import Promote from "@sources/pages/promote";
import Header from "@sources/components/header";
import Footer from "@sources/components/footer";
import Notice from "@sources/components/notice";

import setWallet from "@sources/extensions/modules/wallet";
import { context, initial, reducer } from "@sources/contexts/root";

export default function Page() {
	const [state, dispatch] = useReducer(reducer, initial);
	const { activate } = useWeb3React();

	useEffect(() => setWallet({ activate, dispatch }), [activate, dispatch]);

	return (
		<context.Provider value={{ state, dispatch }}>
			<HashRouter hashType="hashbang">
				<Header />
				<Switch>
					<Route path="/promote">
						<Promote />
					</Route>
					<Route path="/watcher">
						<Watcher />
					</Route>
					<Route path="/">
						<Index />
					</Route>
				</Switch>
				<Footer />
				<Notice />
			</HashRouter>
		</context.Provider>
	);
}
