import { Trans } from "react-i18next";
import { utils } from "ethers";

import getPrices from "@sources/extensions/functions/elements/price";

export default function Head({ size, price, prices }) {
	return getPrices({ prices }).map(({ cost, start, end }, key) => (
		<div
			key={key}
			className={
				price === cost
					? "instruction__step instruction__step--active"
					: price > Number(cost)
					? "instruction__step instruction__step--passive"
					: "instruction__step"
			}
		>
			<Trans
				i18nKey="main.content.inline.tokens"
				values={{
					start: size * start + 1,
					end: size * end,
					price: utils.formatEther(cost),
					ticker: environment.INTERFACE_CONTENT_TICKER,
				}}
			/>
		</div>
	));
}
