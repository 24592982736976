import { filter, size } from "lodash";

export default (
	{ block, vertical, horizontal, owner, ...token },
	estates,
	account
) => {
	return {
		...token,
		vertical,
		horizontal,
		owner: owner.toLowerCase() === (account || "").toLowerCase(),
		attach:
			size(
				filter(
					estates,
					({
						verticalStart,
						verticalEnd,
						horizontalStart,
						horizontalEnd,
						...estate
					}) =>
						block === estate.block &&
						vertical >= verticalStart &&
						vertical <= verticalEnd &&
						horizontal >= horizontalStart &&
						horizontal <= horizontalEnd
				)
			) > 0,
	};
};
