import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Toggle({ active, setActive }) {
	return (
		<button
			className="button button--secondary"
			onClick={() => setActive(active === false)}
		>
			<FontAwesomeIcon icon={faBars} />
		</button>
	);
}
