import { useState, useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { Formik, Form } from "formik";
import { includes } from "lodash";
import * as Yup from "yup";

import Head from "@sources/pages/index/container/interaction/head";
import Body from "@sources/pages/index/container/interaction/body";
import Foot from "@sources/pages/index/container/interaction/foot";

import setToken from "@sources/extensions/functions/interacts/token/set";
import setEstate from "@sources/extensions/functions/interacts/estate/set";
import removeEstate from "@sources/extensions/functions/interacts/estate/remove";
import setMarket from "@sources/extensions/functions/interacts/market/set";
import buyMarket from "@sources/extensions/functions/interacts/market/buy";
import removeMarket from "@sources/extensions/functions/interacts/market/remove";

import { context } from "@sources/contexts/root";

const validation = {
	void: [null],
	attach: [null],
	onsale: [null],
	ownsale: [null],
	owner: [
		Yup.object().shape({
			title: Yup.string()
				.min(2, "side.error.interface.content.validation.min")
				.max(50, "side.error.interface.content.validation.max")
				.required("side.error.interface.content.validation.require"),
			image: Yup.string()
				.required("side.error.interface.content.validation.require")
				.url("side.error.interface.content.validation.link"),
			link: Yup.string()
				.required("side.error.interface.content.validation.require")
				.url("side.error.interface.content.validation.link"),
		}),
		Yup.object().shape({
			price: Yup.number().required(
				"side.error.interface.content.validation.require"
			),
		}),
	],
};

const types = {
	void: ["token.set"],
	owner: ["estate.set", "market.set"],
	onsale: ["market.buy"],
	ownsale: ["market.remove"],
	attach: ["estate.remove"],
};

export default function Interaction({ price, select, setSelect }) {
	const [toggle, setToggle] = useState(0);
	const [loading, setLoading] = useState(false);
	const { dispatch } = useContext(context);
	const { library } = useWeb3React();

	const { type, block, price: payment, frame } = select;

	const size = (frame.ve - frame.vs + 1) * (frame.he - frame.hs + 1);

	return includes(["void", "owner", "onsale", "ownsale", "attach"], type) ===
		true ? (
		<Formik
			initialValues={{ price: "", title: "", image: "", link: "" }}
			validationSchema={validation[type][toggle]}
			onSubmit={(values, { resetForm }) =>
				type === "owner" && toggle === 1
					? setMarket({
							values,
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
							resetForm,
					  })
					: type === "ownsale"
					? removeMarket({
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
					  })
					: type === "onsale"
					? buyMarket({
							price: payment,
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
					  })
					: type === "attach"
					? removeEstate({
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
					  })
					: type === "owner"
					? setEstate({
							values,
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
							resetForm,
					  })
					: setToken({
							price,
							size,
							block,
							frame,
							library,
							dispatch,
							setLoading,
							setSelect,
					  })
			}
		>
			{({ values, errors, isValidating }) => (
				<div className="interactions">
					{types[type].map((index, key) => (
						<Form
							key={key}
							className={`interaction ${
								toggle === key ? "interaction--active" : ""
							}`}
						>
							<Head
								index={key}
								type={index}
								block={block}
								frame={frame}
								setToggle={setToggle}
							/>
							<Body
								type={index}
								values={values}
								errors={errors}
								frame={frame}
							/>
							<Foot
								type={index}
								price={price}
								size={size}
								payment={payment}
								loading={loading}
								isValidating={isValidating}
							/>
						</Form>
					))}
				</div>
			)}
		</Formik>
	) : null;
}
