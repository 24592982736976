import { useContext } from "react";
import { Trans } from "react-i18next";
import { size } from "lodash";

import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { context } from "@sources/contexts/root";

const {
	INTERFACE_LAYOUT_BLOCK: block,
	INTERFACE_LAYOUT_ROW: row,
	INTERFACE_LAYOUT_CELL: cell,
} = environment;

export default function Status({ page }) {
	const { state } = useContext(context);

	const total = size(state.pages.index.element.token);
	const supply = block * row * cell;

	return page === "dashboard" &&
		state.pages.index.attribute.loaded.token === true ? (
		<>
			<div className="header__status__head">
				<span className="header__status__total">{total}</span>
				<span className="header__status__seperator">
					<FontAwesomeIcon icon={faSlash} rotation={90} />
				</span>
				<span className="header__status__supply">{supply}</span>
			</div>
			<div className="header__status__body">
				<Trans
					i18nKey="main.content.inline.status"
					values={{ percent: Math.round((100 * total) / supply) }}
				/>
			</div>
		</>
	) : null;
}
