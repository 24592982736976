import { get } from "lodash";
import getModule from "@sources/extensions/modules/contract";

export default ({
	price,
	size,
	block,
	frame,
	library,
	dispatch,
	setLoading,
	setSelect,
}) => {
	setLoading(true);

	getModule({ library })
		.setToken(block, [frame.vs, frame.ve], [frame.hs, frame.he], {
			value: (price * size).toString(),
		})
		.then((transaction) =>
			transaction.wait().then(() => {
				setLoading(false);
				setSelect({ type: "owner", block, frame });

				dispatch({
					type: "RNC_PUSH",
					payload: {
						type: "success",
						header: "side.success.contract.header",
						content: "side.success.contract.content.token.set",
					},
				});
			})
		)
		.catch((error) => {
			setLoading(false);
			dispatch({
				type: "RNC_PUSH",
				payload: {
					type: "error",
					header: "side.error.contract.header",
					content: get(error, "data.message", get(error, "message", "")),
				},
			});
		});
};
