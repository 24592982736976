import { get } from "lodash";
import object from "@assets/objects/select";

const { INTERFACE_LAYOUT_WIDTH: width, INTERFACE_LAYOUT_HEIGHT: height } =
	environment;

export default ({ type, block, price, frame, select, setSelect, dispatch }) => {
	const object = onCondition({ type, block, frame, select });

	if (object === false)
		return dispatch({
			type: "RNC_PUSH",
			payload: {
				type: "error",
				header: "side.error.interface.header",
				content: "side.error.interface.content.select.limit",
			},
		});

	setSelect(Object.assign(object, { price }));
};

const onCondition = ({ type, block, frame, select }) => {
	let { vs, ve, hs, he } = select.frame;
	const { row, cell } = frame;

	if (block !== select.block || type !== select.type) {
		return { type, block, frame: { vs: row, ve: row, hs: cell, he: cell } };
	} else if (row >= vs && row <= ve && cell >= hs && cell <= he) {
		return object;
	} else if (row === vs - 1 && cell >= hs && cell <= he) {
		if (Math.abs(ve - vs) >= height - 1) return false;

		vs = row;
	} else if (row === ve + 1 && cell >= hs && cell <= he) {
		if (Math.abs(ve - vs) >= height - 1) return false;

		ve = row;
	} else if (cell === hs - 1 && row >= vs && row <= ve) {
		if (Math.abs(he - hs) >= width - 1) return false;

		hs = cell;
	} else if (cell === he + 1 && row >= vs && row <= ve) {
		if (Math.abs(he - hs) >= width - 1) return false;

		he = cell;
	} else {
		return { type, block, frame: { vs: row, ve: row, hs: cell, he: cell } };
	}

	return onValidate({ type, block, frame: select.frame, vs, ve, hs, he });
};

const onValidate = ({ type, frame, block, vs, ve, hs, he }) => {
	for (let y = vs; y <= ve; y++) {
		for (let x = hs; x <= he; x++) {
			const source = document.querySelector(
				`.dashboard__cell[data-block="${block}"][data-row="${y}"][data-column="${x}"]`
			);

			if (get(source, "dataset.type") !== frame.type) return frame;
		}
	}

	return { type, block, frame: { vs, ve, hs, he } };
};
