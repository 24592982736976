import { Field, ErrorMessage } from "formik";
import { Trans, withTranslation } from "react-i18next";

import getImage from "@sources/extensions/functions/validates/image";

export default withTranslation()(function Estate({ values, errors, frame, t }) {
	return (
		<div className="interaction__body">
			<Field
				className="input input--primary"
				placeholder={t("main.input.title")}
				name="title"
				type="text"
				autoComplete="off"
			/>
			<ErrorMessage
				name="title"
				render={(message) => (
					<div className="interaction__error">
						<Trans i18nKey={message} />
					</div>
				)}
			/>
			<Field
				className="input input--primary"
				placeholder={t("main.input.image")}
				name="image"
				type="text"
				autoComplete="off"
				validate={(value) =>
					getImage({
						values,
						errors,
						value,
						vertical: (frame.ve - frame.vs + 1) * 32,
						horizontal: (frame.he - frame.hs + 1) * 32,
						t,
					})
				}
			/>
			<ErrorMessage
				name="image"
				render={(message) => (
					<div className="interaction__error">
						<Trans i18nKey={message} />
					</div>
				)}
			/>
			<Field
				className="input input--primary"
				placeholder={t("main.input.link")}
				name="link"
				type="text"
				autoComplete="off"
			/>
			<ErrorMessage
				name="link"
				render={(message) => (
					<div className="interaction__error">
						<Trans i18nKey={message} />
					</div>
				)}
			/>
		</div>
	);
});
