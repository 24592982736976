import { memo, useState } from "react";
import { split, size } from "lodash";

import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import object from "@assets/objects/select";
import Dashboard from "@sources/pages/index/container/dashboard";
import Instruction from "@sources/pages/index/container/instruction";
import Interaction from "@sources/pages/index/container/interaction";

const {
	CONTRACT_PRICES: costs,
	INTERFACE_LAYOUT_WIDTH: width,
	INTERFACE_LAYOUT_HEIGHT: height,
	INTERFACE_LAYOUT_BLOCK: block,
	INTERFACE_LAYOUT_ROW: row,
	INTERFACE_LAYOUT_CELL: cell,
} = environment;

export default memo(
	function Container({ loading, editing, estate, token, estates, tokens }) {
		const [select, setSelect] = useState(object);

		const prices = split(costs, ",");
		const price = prices[Math.floor(size(tokens) / (row * cell))];

		return estate === true && token === true ? (
			<main className="main main--loading">
				<FontAwesomeIcon icon={faCog} size="lg" spin />
			</main>
		) : (
			<main
				className={
					"main " + (loading === false && editing === true && "main--editing")
				}
			>
				<Instruction
					layout={{ width, height, block, row, cell }}
					prices={prices}
					price={price}
					type={select.type}
				/>
				<Dashboard
					layout={{ block, row, cell }}
					token={tokens}
					estate={estates}
					select={select}
					setSelect={setSelect}
				/>
				<Interaction price={price} select={select} setSelect={setSelect} />
			</main>
		);
	},
	(previous, next) =>
		previous.loading === next.loading &&
		previous.editing === next.editing &&
		previous.estate === next.estate &&
		previous.token === next.token &&
		size(previous.estates) === size(next.estates) &&
		size(previous.tokens) === size(next.tokens)
);
