import { StrictMode } from "react";
import { render } from "react-dom";
import { Web3ReactProvider, createWeb3ReactRoot } from "@web3-react/core";
import { ApolloProvider } from "@apollo/client";

import "@assets/styles/reset.scss";
import "@assets/styles/app.scss";
import "@sources/extensions/modules/i18n";

import { getLibrary } from "@sources/extensions/modules/connectors";
import client from "@sources/extensions/graphs/client";
import Page from "@sources/page";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");

render(
	<StrictMode>
		<Web3ReactProvider getLibrary={getLibrary}>
			<Web3ProviderNetwork getLibrary={getLibrary}>
				<ApolloProvider client={client}>
					<Page />
				</ApolloProvider>
			</Web3ProviderNetwork>
		</Web3ReactProvider>
	</StrictMode>,
	document.getElementById("root")
);
