import { useContext } from "react";

import { context } from "@sources/contexts/root";
import setSelection from "@sources/extensions/functions/helpers/selection";

export default function Selection({
	type,
	block,
	frame,
	price,
	select,
	isSelect,
	setSelect,
}) {
	const { state, dispatch } = useContext(context);

	return (
		<div
			data-type={type}
			data-block={block}
			data-row={frame.row}
			data-cell={frame.cell}
			className={"dashboard__cell " + (isSelect && "dashboard__cell--select")}
			onClick={() =>
				state.resources.session.editing === true &&
				setSelection({ type, block, frame, price, select, setSelect, dispatch })
			}
		/>
	);
}
