import { Trans } from "react-i18next";

import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Market() {
	return (
		<main className="main main--soon">
			<FontAwesomeIcon icon={faHourglassHalf} size="3x" spin />
			<h2>
				<Trans i18nKey="main.header.soon" />
			</h2>
			<Trans i18nKey="main.content.soon" />
		</main>
	);
}
