import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default function Navigator({ page }) {
	return (
		<>
			<Link
				to="/"
				className={
					"button button--navigator " +
					(page === "dashboard" && "button--navigator--active")
				}
			>
				<Trans i18nKey="main.link.dashboard" />
			</Link>
			<Link
				to="/watcher"
				className={
					"button button--navigator " +
					(page === "watcher" && "button--navigator--active")
				}
			>
				<Trans i18nKey="main.link.watcher" />
			</Link>
		</>
	);
}
